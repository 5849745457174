<template>
    <a-modal
        :visible="participantModal.visible"
        @cancel="onClose"
        @ok="onClose"
        :footer="null"
        :bodyStyle="{height: '525px', padding: '30px'}"
        width="1250"
        centered
        >
        <h5 class="mb-4">{{participantModal.type === 'edit'?'Edit Participant' : participantModal.type === 'add'?'Add New Participant':''}}</h5>
        <a-form-model ref="ruleForm" :model="participant" :rules="rules">
            <a-col :span="24">
                <a-form-model-item label="Name" prop="name">
                    <a-input v-model="participant.name" size="large" />
                </a-form-model-item>
            </a-col>
            <a-col :span="24">
                <a-form-model-item label="Email Address" prop="email">
                    <a-input v-model="participant.email" size="large" />
                </a-form-model-item>
            </a-col>
            <a-col :span="24">
                <a-form-model-item label="Company" prop="Company">
                    <a-input v-model="participant.company" size="large" />
                </a-form-model-item>
            </a-col>
            <a-col :span="24">
                <a-form-model-item label="Role" prop="Role">
                    <a-input v-model="participant.role" size="large" />
                </a-form-model-item>
            </a-col>
        </a-form-model>
        <p v-if="duplicateEmail" class="text-danger">This email is a duplicate of another Participant. Please try another!</p>
        <div style="text-align: right;">
            <a-button @click="onClose" class="mt-3 mr-3" style="width: 125px;">CANCEL</a-button>
            <a-button @click="onSubmit" style="width: 125px;" type="primary">{{participantModal.type === 'edit'?'UPDATE' : participantModal.type === 'add'?'CREATE' : ''}}</a-button>
        </div>
    </a-modal>
</template>

<script>
import {validateEmail} from 'bh-mod'

export default {
    data() {
        const checkEmail = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(`Email is required`));
            } else if (!validateEmail(value)) {
                callback(new Error("Please enter a valid email!"));
            } else {
                callback();
            }
        };
        return {
            duplicateEmail:false,
            participant: {
                name:'',
                email:'',
                company:'',
                role:'',
                id:Date.now() + '',
            },
            rules: {
                name: [
                    { required: true, message: 'Please input a name', trigger: 'blur' },
                ],
                email: { required:true,validator: checkEmail, trigger: 'blur' },
            },
        }
    },
    watch:{
        modalType(val){
            if (val == 'edit'){
                this.participant = JSON.parse(JSON.stringify(this.participantModal.user))
            }
        }
    },
    computed:{
        modalType(){
            return this.$store.state.appData.participant.type
        },
        participantModal(){
            return this.$store.state.appData.participant
        },
        filter(){
            return this.$store.state.appData.filter
        },
        transactions(){
            return Object.values(this.$store.state.appData.transactions)
        },
		currentTransaction(){
            return this.$store.state.appData.currentTransaction
        },
		currentReservation(){
            return this.$store.state.appData.currentReservation
        },
        filteredTransactions(){
            return this.$store.getters.filteredTransactions
        },
        currentPage:{
            get(){
                return this.$store.state.appData.currentPage
            },
            set(val){
                this.$store.commit('SET_CONTACTS_PAGE', val)
            }
        },
        instance(){
            return this.$store.state.instance
        },
        loadingPage:{
            get(){
                return this.$store.state.appData.loadingPage
            },
            set(val){
                this.$store.commit('LOAD_PAGE',val)
            }
        }
    },
    methods:{
        onClose(){
            this.$store.commit('CLOSE_PARTICIPANT_MODAL')
            this.participant = {
                name:'',
                email:'',
                company:'',
                role:'',
                id:Date.now() + ''
            }
        },
        onSubmit(){
            this.$refs.ruleForm.validate(valid => {
				if (valid) {
					let participant = { ...this.participant }
					if (this.$store.getters.currentParticipant && this.$store.getters.currentParticipant[participant.email]) return this.duplicateEmail = true
					else this.duplicateEmail = false

					let signingParty = this.$route.meta.reservation ? this.currentReservation.signingParty : this.currentTransaction.signingParty;

					const foundParticipant = signingParty.find(s => s.id === participant.id);

					if (!foundParticipant) {
						return this.$message.error('No valid participants found. Please try again')
					}

					this.$store.commit('LOAD', true);

					const payload = {
						...foundParticipant,
						...participant
					}

					// Added signer key for the backend payload
					payload.signer = {
						...foundParticipant,
						...participant
					}

					let url = `/transactions/:instance/${this.currentTransaction.id}/participants/${participant.id}`
					if (this.$route.meta.reservation) {
						url = `/reservations/:instance/${this.currentReservation.id}/participants/${participant.id}`
					}
					this.$api.put(url, payload).then(async () => {
						if (this.$route.meta.reservation) {
							await this.fetchReservationDetails();
						} else {
							await this.fetchTransactionDetails();
						}
						this.$message.success('Participant details updated successfully.')
					}).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err, 'An error occurred while updating participant details. Please try again.'))
						}
					}).finally(() => {
						this.$store.commit('LOAD', false);
						this.onClose()
					})
				} else {
					return false;
				}
			});
        },

		fetchTransactionDetails() {
			this.$store.commit('LOAD')
			let param = this.$route.params.id
			this.$api.get(`/transactions/:instance/${param}`).then(({ data }) => {
				if (data.envelopes && data.envelopes.length) {
					data.envelopes = data.envelopes.filter(x => !x.deleted)
				}
				this.$store.commit('SET_PROP', { where: ['transactions', data.id], what: data })
				this.$store.commit('SET_CURRENT_TRANSACTION', data)

				this.$store.commit('LOAD', false)
			}).catch((err) => {
				this.$store.commit('LOAD', false)
				this.$router.push('/')
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `Transaction not found`))
				}
			})
		},

		async fetchReservationDetails() {
			this.$store.commit('LOAD')
			try {
				let { data } = await this.$api.get(`reservations/:instance/${this.$route.params.id}`);
				this.$store.commit('SET_CURRENT_RESERVATION', data)
			} catch (err) {
				this.$router.push('/reservations')
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while fetching reservation details. Please try again.`))
				}
			}
			this.$store.commit('LOAD', false)
		}
    },
    created(){

    },
    mounted(){

    }
}
</script>

<style>

</style>
