<template>

    <svg viewBox="0 0 20.709 20.709" v-if="type === 'save'" style="stroke:currentColor">
        <g transform="translate(-3.5 -3.5)">
            <path
                d="M21.131,23.209H6.579A2.079,2.079,0,0,1,4.5,21.131V6.579A2.079,2.079,0,0,1,6.579,4.5H18.012l5.2,5.2V21.131A2.079,2.079,0,0,1,21.131,23.209Z"
                fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            <path d="M20.894,27.815V19.5H10.5v8.315" transform="translate(-1.842 -4.606)" fill="none" 
                stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            <path d="M10.5,4.5V9.7h8.315" transform="translate(-1.842)" fill="none"  stroke-linecap="round"
                stroke-linejoin="round" stroke-width="2" />
        </g>
    </svg>
    <svg style="stroke:currentColor" viewBox="0 0 17.6 21.5" v-else-if="type === 'page-add'">
        <g transform="translate(-5 -2)">
            <path
                d="M15.75,3H7.95A1.95,1.95,0,0,0,6,4.95v15.6A1.95,1.95,0,0,0,7.95,22.5h11.7a1.95,1.95,0,0,0,1.95-1.95V8.85Z"
                fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            <path d="M21,3V8.85h5.85" transform="translate(-5.25)" fill="none"  stroke-linecap="round"
                stroke-linejoin="round" stroke-width="2" />
            <path d="M18,23.85V18" transform="translate(-4.2 -5.25)" fill="none"  stroke-linecap="round"
                stroke-linejoin="round" stroke-width="2" />
            <path d="M13.5,22.5h5.85" transform="translate(-2.625 -6.825)" fill="none" 
                stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
        </g>
    </svg>
    <svg style="fill:currentColor;" viewBox="0 0 11.179 17.887" v-else-if="type === 'select'">
        <path
            d="M10.557,11.5H6.851L8.8,16.249a.649.649,0,0,1-.33.838l-1.718.749a.618.618,0,0,1-.815-.339L4.085,12.985,1.058,16.1A.618.618,0,0,1,0,15.65V.639A.618.618,0,0,1,1.058.19l9.936,10.22A.64.64,0,0,1,10.557,11.5Z"
            />
    </svg>
    <svg style="fill:currentColor" viewBox="0 0 20 19" v-else-if="type === 'zoom-reset'"><text transform="translate(0 15)"
            font-size="14" font-family="OpenSans-Semibold, Open Sans" font-weight="600">
            <tspan x="0" y="0">1:1</tspan>
        </text>
    </svg>
    <svg style="stroke:currentColor" viewBox="0 0 22.27 22.27" v-else-if="type === 'download'">
        <g transform="translate(-3.5 -3.5)">
            <path d="M24.77,22.5V27a2.252,2.252,0,0,1-2.252,2.252H6.752A2.252,2.252,0,0,1,4.5,27V22.5"
                transform="translate(0 -4.487)" fill="none"  stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2" />
            <path d="M10.5,15l5.63,5.63L21.761,15" transform="translate(-1.496 -2.617)" fill="none" 
                stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            <path d="M18,18.013V4.5" transform="translate(-3.365)" fill="none"  stroke-linecap="round"
                stroke-linejoin="round" stroke-width="2" />
        </g>
    </svg>
    <svg style="stroke:currentColor" viewBox="0 0 19.55 21.5" v-else-if="type === 'lock'">
        <g transform="translate(-3.5 -2)">
            <path
                d="M6.45,16.5H20.1a1.95,1.95,0,0,1,1.95,1.95v6.825a1.95,1.95,0,0,1-1.95,1.95H6.45a1.95,1.95,0,0,1-1.95-1.95V18.45A1.95,1.95,0,0,1,6.45,16.5Z"
                transform="translate(0 -4.725)" fill="none"  stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2" />
            <path d="M10.5,11.775v-3.9a4.875,4.875,0,0,1,9.75,0v3.9" transform="translate(-2.1 0)" fill="none"
                stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
        </g>
    </svg>
    <svg style="fill:currentColor" viewBox="0 0 18.779 21.463" v-else-if="type === 'drag'">
        <path
            d="M17.135,5.366a1.7,1.7,0,0,0-1.709,1.71v3.656h-.335V3.345a1.7,1.7,0,0,0-1.709-1.71,1.677,1.677,0,0,0-1.644,1.676v7.42H11.4V1.71A1.7,1.7,0,0,0,9.692,0,1.677,1.677,0,0,0,8.048,1.677v9.054H7.713V3.387A1.7,1.7,0,0,0,6,1.677,1.677,1.677,0,0,0,4.359,3.353v9.892L3.033,11.422A1.677,1.677,0,1,0,.321,13.394l5.265,7.239a2.012,2.012,0,0,0,1.627.829H15.5a2.012,2.012,0,0,0,1.959-1.551l1.111-4.722a8.049,8.049,0,0,0,.214-1.843v-6.3A1.677,1.677,0,0,0,17.135,5.366Z"
         />
    </svg>
    <svg style="stroke:currentColor" viewBox="0 0 21 21" v-else-if="type === 'print'">
        <g transform="translate(-2 -2)">
            <path d="M9,9.65V3H20.4V9.65" transform="translate(-2.2)" fill="none"  stroke-linecap="round"
                stroke-linejoin="round" stroke-width="2" />
            <path
                d="M6.8,22.05H4.9A1.9,1.9,0,0,1,3,20.15V15.4a1.9,1.9,0,0,1,1.9-1.9H20.1A1.9,1.9,0,0,1,22,15.4v4.75a1.9,1.9,0,0,1-1.9,1.9H18.2"
                transform="translate(0 -3.85)" fill="none"  stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2" />
            <path d="M9,21H20.4v7.6H9Z" transform="translate(-2.2 -6.6)" fill="none"  stroke-linecap="round"
                stroke-linejoin="round" stroke-width="2" />
        </g>
    </svg>
    <svg style="stroke:currentColor" viewBox="0 0 20.174 18.129" v-else-if="type === 'rotate-ccw'">
        <g transform="translate(1.414 1.205)">
            <path d="M25.5,6v5.306h5.306" transform="translate(-25.5 -5.117)" fill="none" 
                stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            <path d="M6.725,15.113A7.959,7.959,0,1,0,8.6,6.836L4.5,10.691" transform="translate(-4.505 -4.503)" fill="none"
                stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
        </g>
    </svg>
    <svg style="stroke:currentColor" viewBox="0 0 20.174 18.131" v-else-if="type === 'rotate-cw'">
        <g transform="translate(-3.427 -3.297)">
            <path d="M30.806,6v5.306H25.5" transform="translate(-8.618 -0.615)" fill="none" 
                stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            <path d="M19.968,15.112a7.958,7.958,0,1,1-1.875-8.277l4.094,3.855" fill="none" 
                stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
        </g>
    </svg>
    <svg style="fill:currentColor;" viewBox="0 0 15.52 20.693" v-else-if="type === 'contract-file'">
        <path
            d="M9.053,5.5V0H.97A.968.968,0,0,0,0,.97V19.723a.968.968,0,0,0,.97.97H14.55a.968.968,0,0,0,.97-.97V6.466h-5.5A.973.973,0,0,1,9.053,5.5ZM2.587,2.91a.323.323,0,0,1,.323-.323H6.143a.323.323,0,0,1,.323.323v.647a.323.323,0,0,1-.323.323H2.91a.323.323,0,0,1-.323-.323Zm0,2.587a.323.323,0,0,1,.323-.323H6.143a.323.323,0,0,1,.323.323v.647a.323.323,0,0,1-.323.323H2.91a.323.323,0,0,1-.323-.323ZM10.379,15.52h1.907a.647.647,0,1,1,0,1.293H10.379a1.737,1.737,0,0,1-1.562-.964.448.448,0,0,0-.411-.264.441.441,0,0,0-.4.25l-.31.62a.646.646,0,0,1-.578.357l-.046,0a.644.644,0,0,1-.567-.44l-.68-2.039-.429,1.288a1.745,1.745,0,0,1-1.657,1.193h-.5a.647.647,0,1,1,0-1.293h.5a.453.453,0,0,0,.43-.31L4.9,13A.97.97,0,0,1,6.74,13L7.3,14.685a1.814,1.814,0,0,1,2.667.572A.461.461,0,0,0,10.379,15.52ZM15.237,4.244,11.28.283A.969.969,0,0,0,10.593,0h-.247V5.173H15.52V4.927A.967.967,0,0,0,15.237,4.244Z"
            />
    </svg>
    <svg style="stroke:currentColor" viewBox="0 0 18.442 20.27" v-else-if="type === 'trash'">
        <g transform="translate(-3.5 -2)">
            <path d="M4.5,9H20.943" transform="translate(0 -2.346)" fill="none"  stroke-linecap="round"
                stroke-linejoin="round" stroke-width="2" />
            <path
                d="M20.289,6.654V19.443a1.827,1.827,0,0,1-1.827,1.827H9.327A1.827,1.827,0,0,1,7.5,19.443V6.654m2.74,0V4.827A1.827,1.827,0,0,1,12.067,3h3.654a1.827,1.827,0,0,1,1.827,1.827V6.654"
                transform="translate(-1.173)" fill="none"  stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2" />
            <path d="M15,16.5v5.481" transform="translate(-4.106 -5.279)" fill="none" 
                stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            <path d="M21,16.5v5.481" transform="translate(-6.452 -5.279)" fill="none" 
                stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
        </g>
    </svg>
    <svg style="fill:currentColor;" viewBox="0 0 21.688 21.691" v-else-if="type === 'zoom-in'">
        <path
            d="M12.878,8.133V9.489A.51.51,0,0,1,12.37,10H10V12.37a.51.51,0,0,1-.508.508H8.133a.51.51,0,0,1-.508-.508V10H5.253a.51.51,0,0,1-.508-.508V8.133a.51.51,0,0,1,.508-.508H7.625V5.253a.51.51,0,0,1,.508-.508H9.489A.51.51,0,0,1,10,5.253V7.625H12.37A.51.51,0,0,1,12.878,8.133Zm8.515,12.06-1.2,1.2a1.012,1.012,0,0,1-1.436,0L14.53,17.169a1.016,1.016,0,0,1-.3-.72v-.69a8.807,8.807,0,1,1,1.525-1.525h.69a1.016,1.016,0,0,1,.72.3l4.223,4.223A1.025,1.025,0,0,1,21.393,20.194ZM14.572,8.811a5.761,5.761,0,1,0-5.761,5.761A5.756,5.756,0,0,0,14.572,8.811Z"
             />
    </svg>

    <svg style="fill:currentColor;" v-else-if="type === 'zoom-out'" viewBox="0 0 21.886 21.889">
        <path d="M13,8.208V9.576a.514.514,0,0,1-.513.513H5.3a.514.514,0,0,1-.513-.513V8.208A.514.514,0,0,1,5.3,7.695h7.182A.514.514,0,0,1,13,8.208Zm8.592,12.171-1.21,1.21a1.022,1.022,0,0,1-1.449,0l-4.266-4.262a1.025,1.025,0,0,1-.3-.727v-.7A8.888,8.888,0,1,1,15.9,14.364h.7a1.025,1.025,0,0,1,.727.3l4.262,4.262A1.035,1.035,0,0,1,21.588,20.378ZM14.706,8.892a5.814,5.814,0,1,0-5.814,5.814A5.809,5.809,0,0,0,14.706,8.892Z"  />
    </svg>
    <svg style="stroke:currentColor" viewBox="0 0 30 33.012" v-else-if="type === 'unlock'">
        <g transform="translate(-3 -1.488)">
            <path d="M7.5,16.5h21a3,3,0,0,1,3,3V30a3,3,0,0,1-3,3H7.5a3,3,0,0,1-3-3V19.5a3,3,0,0,1,3-3Z" fill="none"
                stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
            <path d="M10.5,16.5v-6A7.5,7.5,0,0,1,25.35,9" fill="none" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="3" />
        </g>
    </svg>

</template>

<script>
export default {
    props:['type','color'],
    
}
</script>

<style>

</style>