<template>
    <a-modal
        :visible="noteModal.visible"
        @cancel="onClose"
        @ok="onClose"
        :footer="null"
        :bodyStyle="{height: '430px', padding: '30px'}"
        width="520px"
        centered
        >
        <h5 class="mb-4">{{noteType === 'edit'?'Edit Note' : noteType === 'add'?'Add New Note':''}}</h5>
        <a-form-model ref="ruleForm" :model="note" :rules="rules">
            <a-col :span="24">
                <a-form-model-item label="Subject" prop="subject">
                    <a-input v-model="note.subject" size="large" />
                </a-form-model-item>
            </a-col>
            <a-col :span="24">
                <a-form-model-item label="Note" prop="content">
                    <a-textarea
                        v-model="note.content"
                        :auto-size="{ minRows: 5, maxRows: 5 }"
                        placeholder="Add note"
                        />
                </a-form-model-item>
            </a-col>
        </a-form-model>

        <div style="text-align: right;">
            <a-button @click="onClose" class="mt-3 mr-3" style="width: 125px;">CANCEL</a-button>
            <a-button @click="onSubmit" style="width: 125px;" type="primary">{{noteType === 'edit'?'UPDATE' : noteType === 'add'?'CREATE' : ''}}</a-button>
        </div>
    </a-modal>
</template>

<script>

export default {
    components:{ },
    data() {
        return {
            note: {
                subject:'',
                content:'',
                createdBy:'',
                updatedAt:Date.now()
            },
            rules: {
                subject: [
                    { required: true, message: 'Please input a subject', trigger: 'blur' },
                ],
            },
        }
    },
    watch:{
        noteType(val){
            if (val == 'edit'){
                let note = this.currentNotes.find(x => x.id === this.noteModal.note)
                this.note = JSON.parse(JSON.stringify(note))
            }
        }
    },
    computed:{
        transaction(){
            return this.$store.state.appData.currentTransaction
        },
		reservation(){
            return this.$store.state.appData.currentReservation
        },
        currentNotes(){
            let result = this.transaction.notes || this.reservation.notes || []
            return result
        },
        noteType(){
            return this.$store.state.appData.note.type
        },
        noteModal(){
            return this.$store.state.appData.note
        },
        instance(){
            return this.$store.state.instance
        },
    },
    methods:{
        onClose(){
            this.$store.commit('CLOSE_NOTE_MODAL')
            this.note = {
                subject:'',
                content:'',
                id:Date.now() + '',
                createdBy:'',
            }
        },
        onSubmit() {
			this.$refs.ruleForm.validate(async valid => {
				if (valid) {
					let currentNotes = this.currentNotes

					let url = `/transactions/:instance/${this.transaction.id}`
					if (this.$route.meta.reservation) {
						url = `/reservations/:instance/${this.reservation.id}`
					}
					if (this.noteType == 'edit') {
						let notes = currentNotes.map(x => x.id === this.note.id ? { ...this.note, updatedAt: Date.now() } : x)
						this.$api.put(url, { notes }).then(({ data }) => {
							this.$store.commit('SET_PROP', { where: [this.$route.meta.reservation ? 'currentReservation' : 'currentTransaction', 'notes'], what: notes })
							this.onClose()
						}).catch((err) => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
					} else {
						let id = Date.now() + ''
						this.note.id = id
						this.note.updatedAt = Date.now()

						currentNotes.push(this.note)
						this.$api.put(url, { notes: currentNotes }).then(({ data }) => {
							this.$store.commit('SET_PROP', { where: [this.$route.meta.reservation ? 'currentReservation' : 'currentTransaction', 'notes'], what: currentNotes })
							this.onClose()
						}).catch((err) => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
						this.onClose()
					}
				} else {
					return false;
				}
			});
		}
    },
    created(){

    },
    mounted(){

    }
}
</script>

<style>

</style>
