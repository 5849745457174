<template>
    <!-- Participant Card -->
    <a-card v-if="!addnew" class="participant-holder relative" :bodyStyle={padding:0} style="width: 280px; height: 270px; overflow: hidden;">
        <div class="top-section">

            <!-- If user has avatar -->
            <div v-if="participant.avatar" class="avatar-icon">
                <div class="inside" :style="{ 'background-image': 'url(' + `${participant.avatar}` + ')' }" />
            </div>

            <!-- Else -->
            <div v-else class="avatar-icon">
                <a-icon type="user" style="font-size: 50px; margin-top: 20px; color: #DDDCDF;" />
            </div>
        </div>


        <div class="middle-section">
            <h5>{{participant.name}}</h5>
            <div style="min-height: 22px;">{{participant.company}}</div>
            <small>{{participant.email}}</small>
            <div style="color: #98A0A6; font-size: 12px;"> {{participant.role}}&nbsp;</div>
        </div>


        <div class="absolute dF px-3 jSB w-full" style="bottom: 12px; left: 0;">
            <div :span="12" class="dF" style="padding: 0;">

                <!-- Lock Icon -->
                <svg v-show="participant.locked && !participant.owner" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#9EA0A5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>

                <!-- Crown Icon -->
                <svg v-show="participant.owner" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20.4 16.32" fill="#FF9027"> <path id="Icon_awesome-crown" data-name="Icon awesome-crown" class="cls-1" d="M16.83,14.28H3.57a.511.511,0,0,0-.51.51v1.02a.511.511,0,0,0,.51.51H16.83a.511.511,0,0,0,.51-.51V14.79A.511.511,0,0,0,16.83,14.28Zm2.04-10.2a1.53,1.53,0,0,0-1.53,1.53,1.5,1.5,0,0,0,.14.631L15.172,7.624a1.019,1.019,0,0,1-1.409-.37l-2.6-4.545a1.53,1.53,0,1,0-1.932,0l-2.6,4.545a1.019,1.019,0,0,1-1.409.37l-2.3-1.383a1.529,1.529,0,1,0-1.393.9,1.562,1.562,0,0,0,.245-.025l2.3,6.145H16.32l2.3-6.145a1.562,1.562,0,0,0,.245.025,1.53,1.53,0,1,0,0-3.06Z"/> </svg>

                <template v-if="participant.docs">
                    <a-tooltip overlayClassName="change-tooltip-color" :title="`Signee of ${participant.docs} Document(s)`">
                        <!-- <div class="dF relative"> -->
                            <a-badge dot :offset="[-3,3]">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#9EA0A5" stroke="white" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                <!-- <div class="mr-3" style="line-height: 30px; font-size: 20px;">{{participant.docs}}</div> -->
                            </a-badge>
                        <!-- </div> -->
                    </a-tooltip>
                </template>
            </div>

            <div  style="text-align: right; line-height: 30px; padding: 0;" v-if="false && !participant.locked && editingRights">
                <MoreActions v-show="true" :val="''" :items="[{label:'Edit',value:'edit',icon:'edit'}]" @action="roleOptions"/>
            </div>
        </div>

    </a-card>


    <!-- Add New Card -->
    <a-card v-else-if="addnew && editingRights" @click="$emit('addnew')" class="participant-holder" style="background-color: #ECE9F1; border: 2px dashed #D0C9D6; user-select: none; padding-top:20px;">
        <div class="top-section">
            <div style="background-color: #D0C9D6;" class="avatar-icon">
                <a-icon type="user" style="font-size: 50px; margin-top: 20px; color: #ECE9F1;" />
            </div>
        </div>
        <div class="dF" style="width: 232px; height: 120px; line-height: 120px; justify-content: center;">
            <a-icon class="mr-2" style="line-height: 130px; font-size: 24px;" type="plus-circle" />
            <div style="text-decoration: underline;">Add New</div>
        </div>
    </a-card>
</template>

<script>

import MoreActions from 'bh-mod/components/common/MoreActions'

export default {
	components:{MoreActions},
    props:['participant','addnew'],
	watch:{

	},
    computed:{
		editingRights(){
            let canEdit = false
            if (this.$p >= 40){
                return true
            } else if(this.currentTransaction && this.currentTransaction.owners) {
                this.currentTransaction.owners.forEach( x => {
                    if (x.id == this.user.id){
                        canEdit = true
                    }
                })
                return canEdit
            }
			return canEdit
        },
        user(){
            return this.$store.state.user.user
        },
		reservation() {
			return this.$store.state.appData.currentReservation
		},
		currentTransaction(){
			if (this.tableFor === 'reservation' && this.reservation && this.reservation.id) {
				return {...this.reservation, owners: [this.reservation.createdBy]}
			} else {
				return this.$store.state.appData.currentTransaction
			}
        },
    },
    data() {
        return {

		}
	},
    methods:{
		roleOptions(val){
            if (val.key == 'edit'){
                this.$emit('edituser')
            } else if (val.key == 'delete'){
                this.$emit('deleteuser')
            }
        }
	},
	created(){

	}
}
</script>

<style lang="scss">
    .participant-holder {
        padding:30px 0 0;
        min-width:280px;
        // width: 280px;
        // height: 310px;
        box-shadow: 0px 2px 6px rgba(47, 83, 151, 0.1);
        cursor: pointer;
        .ant-card-body {
            padding-bottom: 0;
        }

        .top-section {
            // width: 232px;
            // height: 145px;
            // padding-top: 40px;
            text-align: center;

            .avatar-icon {
                border-radius: 100px;
                width: 100px;
                height: 100px;
                background-color: #F4F3F6;
                margin-left: auto;
                margin-right: auto;
                .inside {
                    width: 100px;
                    height: 100px;
                    border-radius: 100px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: 50%;
                }
            }
        }
        .middle-section {
            // width: 232px;
            // height: 95px;
            text-align: center;
            padding-top: 15px;
        }
        .bottom-section {
            // width: 232px;
            // height: 41px;
        }
    }
</style>

<style>
    .participant-holder:hover{
		transition: all 0.2s;
		box-shadow: 0px 7px 20px rgba(47, 83, 151, 0.15);
    }
</style>
